import styled, { createGlobalStyle, css } from "styled-components";
import { getResponsiveWidthRules, ResponsiveSize } from "styles/helpers";
import { down } from "styled-breakpoints";

type ContainerProps = {
  $width?: ResponsiveSize;
  isFullscreenOnMobile?: boolean;
};

export const ModalContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  height: auto;
  max-height: initial;
  margin: auto;
  position: relative;

  ${({ isFullscreenOnMobile }) =>
    isFullscreenOnMobile &&
    css`
      ${down("md")} {
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        height: 100%;
      }
    `}

  ${({ $width }) => getResponsiveWidthRules($width)}
`;

type GlobalProps = {
  overlayClassName: string;
  contentClassName: string;
  isFullscreenOnMobile?: boolean;
};

export const GlobalStyles = createGlobalStyle<GlobalProps>`
  
  body.ReactModal__Body--open > #__next {
    filter: blur(6px);
    overflow-x: hidden;
    
    ${({ isFullscreenOnMobile }) =>
      isFullscreenOnMobile &&
      css`
        ${down("md")} {
          filter: none;
        }
      `}
  }
  
  .${({ overlayClassName }) => overlayClassName} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    // modal can overflow the screen
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2000; // 1000 is used by panel theme
    // center align the modal
    // using flex justify-content and align-items would center the modal, but  when the flex item overflows the 
    // container the top becomes inaccessible
    // So we just set display to flex, and set margin of child to auto
    // https://stackoverflow.com/a/33455342/4560636
    display: flex;
  }
  
  .${({ contentClassName }) => contentClassName} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    width: ${({ isFullscreenOnMobile }) =>
      isFullscreenOnMobile ? "100%" : "auto"};
    height: ${({ isFullscreenOnMobile }) =>
      isFullscreenOnMobile ? "100%" : "auto"};
    // center align the modal
    // using flex justify-content and align-items would center the modal, but  when the flex item overflows the 
    // container the top becomes inaccessible
    // So the margin is set to auto and parent display is set to flex
    // https://stackoverflow.com/a/33455342/4560636
    margin: auto;
    // modal size grows with content
    overflow-y: initial !important;
    
    &:focus {
      outline: none;
    }
  }
`;
