import { useCallback, useState } from "react";

type UseModal = {
  isOpen: boolean;
  handleCloseModal: VoidFunction;
  handleOpenModal: VoidFunction;
};

/**
 *  creates and manages an `isOpen` state to be used as the prop of Modal component
 * @param {boolean} initialIsOpen - the initial value for `isOpen` state
 */
function useModal(initialIsOpen?: boolean): UseModal {
  const [isOpen, setIsOpen] = useState(initialIsOpen || false);

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    handleCloseModal,
    handleOpenModal,
  };
}

export default useModal;
