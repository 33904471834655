import styled from "styled-components";
import SvgIcon from "../SvgIcon";

type CloseButtonProps = {
  top?: string;
  right?: string;
  left?: string;
};

export const StyledCloseButton = styled.button<CloseButtonProps>`
  background-color: #fff;
  border: none;
  outline: none;
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  padding: 8px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 5px 0 rgba(0, 0, 0, 0.2);

  ${SvgIcon} {
    fill: #999;
  }

  @media (hover) {
    &:hover {
      outline: none;
      border: none;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 5px 0 rgba(0, 0, 0, 0.4);

      ${SvgIcon} {
        fill: #666;
      }
    }
  }
`;
