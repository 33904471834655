import styled from "styled-components";
import { up } from "styled-breakpoints";

export const Header = styled.div`
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #f1f1f1;
  padding: 0 50px 0 25px;
  text-align: left;
  display: flex;
  align-items: center;
  min-height: 40px;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.mainTextColor};

  ${up("md")} {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  background-color: #fff;
  flex-grow: 1;
`;
