import React from "react";
import * as Styled from "./styles";
import { SharedTextButtonProps } from "./types";
import useTextButtonProps from "./useTextButtonProps";

export type Props = SharedTextButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
  };

const AnchorTextButton = (props: Props): React.ReactElement => {
  const {
    styledProps,
    disabled,
    restProps,
    children,
  } = useTextButtonProps<Props>(props);

  if (disabled) {
    return (
      <Styled.AnchorTextButton {...styledProps} as={"div"}>
        {children}
      </Styled.AnchorTextButton>
    );
  }
  return (
    <Styled.AnchorTextButton {...styledProps} {...restProps}>
      {children}
    </Styled.AnchorTextButton>
  );
};

export default AnchorTextButton;
