import React from "react";
import { StyledCloseButton } from "./styles";
import SvgIcon from "../SvgIcon";
import CloseIcon from "../../svg/close.svg";

type CloseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  top?: string;
  right?: string;
  left?: string;
};

const CloseButton = (props: CloseButtonProps): React.ReactElement => {
  const { top = "10px", right = "10px", left, ...rest } = props;

  return (
    <StyledCloseButton top={top} right={right} left={left} {...rest}>
      <SvgIcon component={CloseIcon} size={{ xs: 9 }} />
    </StyledCloseButton>
  );
};

export default CloseButton;
