import * as React from "react";
import * as Styled from "./styles";
import { renderChildren } from "./helpers";
import { SharedButtonProps } from "./types";
import useButtonProps from "./useButtonProps";

export type ButtonProps = SharedButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = (props: ButtonProps): React.ReactElement => {
  const {
    styledProps,
    disabled,
    restProps,
    children,
  } = useButtonProps<ButtonProps>(props);

  return (
    <Styled.Button {...styledProps} {...restProps} disabled={disabled}>
      {renderChildren(
        children,
        !!props.isLoading,
        !!props.small,
        styledProps.$oppositeColor
      )}
    </Styled.Button>
  );
};

export default Button;
