import React, { RefObject, useCallback, useEffect, useRef } from "react";
import { ResponsiveSize } from "styles/helpers";
import usePrevious from "hooks/usePrevious";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import * as Styled from "./styles";
import ReactModal from "react-modal";
import CloseButton from "../CloseButton/CloseButton";

ReactModal.setAppElement("#__next");

export type ModalProps = {
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  width?: ResponsiveSize;
  isFitToContent?: boolean;
  children: React.ReactNode;
  isCloseableByOverlay?: boolean;
  isFullscreenOnMobile?: boolean;
  isCloseButtonDisplayed?: boolean;
  scrollableElementRef?: RefObject<HTMLElement | null>;
};

const Modal = (props: ModalProps): React.ReactElement => {
  const {
    isOpen,
    onClose,
    children,
    className,
    isFitToContent,
    scrollableElementRef,
    isCloseableByOverlay = true,
    isFullscreenOnMobile = false,
    isCloseButtonDisplayed = true,
    width = { xs: "90vw", sm: "75vw", md: "65vw", lg: "55vw", xl: "800px" },
  } = props;

  const modalId = useRef(Math.ceil(Math.random() * 100000) + "");
  const overlayClassName = "modal-overlay-" + modalId.current;
  const contentElementRef = useRef<HTMLElement | null>(null);

  const contentClassName = "modal-content-" + modalId.current;

  const wasOpen = usePrevious(isOpen);
  // This is the target element used by `disabledBodyScroll` and should be used by `enableBodyScroll` to correctly
  // remove the scroll lock
  const scrollLockTargetElementRef = useRef<HTMLElement | null>(null);

  // clear body-scroll-lock if modal gets unmounted
  useEffect(() => {
    return function onUnmount() {
      clearAllBodyScrollLocks();
    };
  }, []);

  // CLOSED, enable the body scroll
  useEffect(() => {
    if (wasOpen && !isOpen) {
      enableBodyScroll(scrollLockTargetElementRef.current);
    }
  }, [isOpen, wasOpen]);

  // first, unlock the body scroll, then call the onClose() callback (if any)
  // this is set as `onClick` handler of close button and `onRequestClose` of react-modal (which is called when user
  // clicks on overlay or presses Esc)
  const handleClose = useCallback(() => {
    enableBodyScroll(scrollLockTargetElementRef.current);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <>
      <Styled.GlobalStyles
        overlayClassName={overlayClassName}
        contentClassName={contentClassName}
        isFullscreenOnMobile={isFullscreenOnMobile}
      />
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={isCloseableByOverlay}
        // bodyOpenClassName={bodyWithModalClassName}
        overlayClassName={overlayClassName}
        className={contentClassName}
        contentRef={(node) => {
          contentElementRef.current = node;
        }}
        aria={{
          labelledby: modalId.current,
          modal: true,
        }}
        onAfterOpen={() => {
          scrollLockTargetElementRef.current =
            scrollableElementRef?.current ?? contentElementRef.current;
          if (scrollLockTargetElementRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            disableBodyScroll(scrollLockTargetElementRef.current!);
          }
        }}
      >
        <Styled.ModalContainer
          $width={isFitToContent ? undefined : width}
          className={className}
          isFullscreenOnMobile={isFullscreenOnMobile}
        >
          {isCloseButtonDisplayed ? (
            <CloseButton
              onClick={handleClose}
              top={"8px"}
              right={"10px"}
              style={{ zIndex: 2 }}
            />
          ) : null}
          {children}
        </Styled.ModalContainer>
      </ReactModal>
    </>
  );
};

export default Modal;
