import React, { ReactElement } from "react";
import Modal, { ModalProps } from "../Modal";
import * as Styled from "./styles";

export type ModalWithHeaderProps = ModalProps & {
  title?: string | (() => ReactElement);
};

const ModalWithHeader = (props: ModalWithHeaderProps): ReactElement => {
  const { children, title, ...restProps } = props;

  return (
    <Modal {...restProps}>
      <Styled.Header>{title}</Styled.Header>
      <Styled.Body>{children}</Styled.Body>
    </Modal>
  );
};

export default ModalWithHeader;
