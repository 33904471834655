import React from "react";
import * as Styled from "./styles";
import { renderChildren } from "./helpers";
import { SharedButtonProps } from "./types";
import useButtonProps from "./useButtonProps";

type Props = SharedButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
  };

const AnchorButton = (props: Props): React.ReactElement => {
  const { styledProps, disabled, children, restProps } = useButtonProps<Props>(
    props
  );

  if (disabled) {
    return (
      <Styled.AnchorButton {...styledProps} as={"div"}>
        {renderChildren(
          children,
          !!props.isLoading,
          !!props.small,
          styledProps.$oppositeColor
        )}
      </Styled.AnchorButton>
    );
  }
  return (
    <Styled.AnchorButton {...styledProps} {...restProps}>
      {renderChildren(
        children,
        !!props.isLoading,
        !!props.small,
        styledProps.$oppositeColor
      )}
    </Styled.AnchorButton>
  );
};

export default AnchorButton;
